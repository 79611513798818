<template>
  <b-container  fluid>
        <b-row>
          <b-col>
              <div style="color:#0057a2;  " class="pt-5 mb-0">
                <b style="font-size: 34px;">Coming Soon...</b>
              </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
              <div style="color:#0057a2;">
                <img src="../assets/img/cover.png" width="100%" alt="">
                <div class="d-flex justify-content-center ">
                  <b-button variant="text" href="https://www.facebook.com/Yalladar.Egypt">
                    <img src="../assets/img/facebook.svg" height="35" alt=""><br>
                    <b style="font-size: 12px;">Visit Our Facebook Page</b>
                  </b-button>
                  <b-button @click="callNumber" variant="text">
                    <img src="../assets/img/cellphone.svg" height="35" alt=""><br>
                    <b style="font-size: 12px;">Contact Us (+201114040015)</b><br>
                  </b-button>
                </div>
              </div>
          </b-col>
        </b-row>
        
  </b-container>
</template>

<script>
export default {
  name: 'ComingSoon',
  data() {
    return {
      email: ''
    }
  },
  methods: {
    faceBookPage() {
      window.location.href = 'https://www.facebook.com/Yalladar.Egypt'
    },
    callNumber() {
      const phoneNumber = '+201114040015'
      window.open(`tel:${phoneNumber}`)
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 4rem;
}
.coming-soon-page{
  width: 100%;
  height:100vh
}

</style>
